<script lang="ts" setup>
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

const props = defineProps<BorrachoEditorProps>()
const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void
}>()

interface BorrachoEditorProps {
  modelValue: string
  placeholder: 'Announcement email content...'
}

let editor: null | InstanceType<typeof Quill> = null

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ header: 1 }, { header: 2 }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ['link'],
  ['clean'],
]

function htmlEncode(s) {
  const el = document.createElement('div')
  el.innerText = el.textContent = s
  s = el.innerHTML
  return s
}

function exportHtml() {
  // const html = htmlEncode((editor?.root?.innerHTML ?? '').trim())
  const html = htmlEncode(editor?.getSemanticHTML() ?? '').trim()
  emit('update:modelValue', html)
}

function resetFromProps() {
  if (!editor)
    return

  if (props.modelValue.trim()) {
    const content = editor.clipboard.convert({ html: decodeHTMLEntities(props.modelValue.trim()) })
    editor.setContents(content)
  }
}

onMounted(() => {
  // configure Quill to use inline styles so the email's format properly
  const DirectionAttribute = Quill.import('attributors/attribute/direction')
  Quill.register(DirectionAttribute, true)

  const AlignClass = Quill.import('attributors/class/align')
  Quill.register(AlignClass, true)

  const BackgroundClass = Quill.import('attributors/class/background')
  Quill.register(BackgroundClass, true)

  const ColorClass = Quill.import('attributors/class/color')
  Quill.register(ColorClass, true)

  const DirectionClass = Quill.import('attributors/class/direction')
  Quill.register(DirectionClass, true)

  const FontClass = Quill.import('attributors/class/font')
  Quill.register(FontClass, true)

  const SizeClass = Quill.import('attributors/class/size')
  Quill.register(SizeClass, true)

  const AlignStyle = Quill.import('attributors/style/align')
  Quill.register(AlignStyle, true)

  const BackgroundStyle = Quill.import('attributors/style/background')
  Quill.register(BackgroundStyle, true)

  const ColorStyle = Quill.import('attributors/style/color')
  Quill.register(ColorStyle, true)

  const DirectionStyle = Quill.import('attributors/style/direction')
  Quill.register(DirectionStyle, true)

  const FontStyle = Quill.import('attributors/style/font')
  Quill.register(FontStyle, true)

  const SizeStyle = Quill.import('attributors/style/size')
  Quill.register(SizeStyle, true)

  editor = new Quill('.borracho-editor__edit', {
    placeholder: props.placeholder,
    modules: {
      toolbar: toolbarOptions,
    },
    theme: 'snow',
  })

  editor.on('text-change', exportHtml)

  nextTick(() => {
    resetFromProps()
  })
})

onBeforeUnmount(() => {
  if (editor)
    editor = null
})
</script>

<template>
  <div class="borracho-editor">
    <div class="borracho-editor__toolbar" />
    <div class="borracho-editor__edit" />
  </div>
</template>

<style lang="scss">
.borracho-editor {
  @apply rounded-xl theme-neutral-light bg-t-bg text-t-text p-2;
}

.ql-container {
  @apply text-sm font-sans text-t-text border-0;
}

.ql-container.ql-snow {
  @apply border-0;
}

.ql-editor {
  @apply p-2 pt-4;

  font-family: helvetica, "helvetica neue", arial, verdana, sans-serif;
  font-size: 13px;

  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  ol {
    counter-reset: list !important;
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    li {
      counter-increment: list !important;

      &:not([data-list="bullet"])::before {
        content: counter(list) "." !important;
        margin-left: 0 !important;
        margin-right: 5px !important;
      }

      &:is([data-list="bullet"])::before {
        content: '•';
        margin-left: 0 !important;
        margin-right: 9px !important;
      }
    }
  }

  ul {
    counter-reset: list;
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    li::before {
      content: '•';
      margin-left: 0 !important;
      margin-right: 9px !important;
    }
  }

  li {
    display: block;
    text-align: -webkit-match-parent;
    margin-left: 15px;
    padding-left: 0 !important;
    counter-increment: list;
    position: relative;

    &::before {
      position: absolute;
      right: 100%;
    }
  }

  ::marker, .ql-ui::before {
    content: none !important;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}

.ql-toolbar.ql-snow {
  @apply border-0 p-2 bg-t-bg theme-neutral text-t-text-light rounded-lg;
}

.ql-snow .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  @apply stroke-current;
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  @apply fill-current;
}

.ql-snow .ql-picker {
  @apply text-current;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  @apply text-t-text;
}
</style>
